export const METRICS_ENDPOINT = '/ts-metrics/';

export const METRICS_NAMES_LIST = [
  'CLS',
  'FCP',
  'FID',
  'LCP',
  'TTFB',
  'TBT',
];

export type MetricsNamesType = {
  name: 'CLS'
  | 'FCP'
  | 'FID'
  | 'LCP'
  | 'TTFB'
  | 'TBT'
  value: number | string
};
